@tailwind base;
@tailwind components;
@tailwind utilities;

.btn.loading {
  width: 1.5rem !important;
  display: flex !important;
  margin: 0 auto !important;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 30px;
  -webkit-mask-size: 30px;
  color: #000;
  background-color:#000;
}

.loading-indicator:before {
  content: '';
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
  
.loading-indicator:after {
  content: ' ';
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.2rem;        
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0474bf;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.validating span.input-container,
.validating .css-qunop-control {
  position: relative;
}
.validating span.input-container:after,
.validating .css-qunop-control:after {
  content: '...';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0%, -50%);
    mask-image: url('data:image/svg+xml,%3Csvg width=%2724%27 height=%2724%27 stroke=%27%23000%27 viewBox=%270 0 24 24%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate%28360deg%29%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class=%27spinner_V8m1%27%3E%3Ccircle cx=%2712%27 cy=%2712%27 r=%279.5%27 fill=%27none%27 stroke-width=%273%27%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    width: 15px;
    height: 15px;
    mask-size: 100%;
    color: #000;
    mask-position: center;
    background-color: black;
    mask-repeat: no-repeat;
}
.validating .css-qunop-control:after {
  right: 50px;
}

.form-control.valid input,
.form-control.valid .css-qunop-control {
  border-color: green;
}
.form-control.valid span.input-container {
  position: relative;
}
.form-control.valid span.input-container:after{
  content: '...';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%);
  mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>');
  width: 15px;
  height: 15px;
  mask-size: 100%;
  color: green;
  mask-position: center;
  background-color: green;
  mask-repeat: no-repeat;
}

input[type=file] {
  padding-left: 0px;
  cursor: pointer;
}
input[type=file]::file-selector-button {
  background-color: var(--fallback-a,oklch(var(--a)/var(--tw-bg-opacity)));
  color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 15px 10px 15px;
  margin-right: 20px;
  transition: .5s;
  height: 100%;
}

.header-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./assets/2_jovem-cientista.png');
}

.footer-bg {
  background-color: white;
  background-size: contain;
  background-repeat: repeat-x;
  background-position: bottom;
  background-image: url('./assets/footer.png');
}

@media (max-width: 576px) { 
  .header-bg {
    background-image: url('./assets/2_jovem-cientista-mobile.png');
  }
  .footer-bg {
    background-size: cover;
    background-repeat: repeat-x;
  }
}

.input-password-icon {

}
.input-password-icon.show::after{
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>');
}
.input-password-icon.hide::after{
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>');
}

.rdt_TableRow {
  cursor: pointer;
}
.rdt_TableRow:hover {
  background-color: #E9E9E9;
}
/*
.rdt_TableContainer {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.rdt_Pagination {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
*/